// About.js
import React from 'react';
import { Box, Typography, Avatar, Link } from '@mui/material';

const About = () => {
  return (
    <Box sx={styles.container}>
      {/* Logo and Instagram */}
      <Box sx={styles.logoSection}>
        <Avatar
          src={require('../assets/images/salonim.live.jpg')} // Replace with actual logo URL
          sx={styles.logo}
          alt="Salonim Logo"
        />
        <Box sx={styles.instagramSection}>
          <Avatar
            src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" // Instagram icon URL
            sx={styles.instagramIcon}
            alt="Instagram Icon"
          />
          <Link
            href="https://www.instagram.com/salonim.live/"
            target="_blank"
            underline="none"
            sx={styles.instagramLink}
          >
            https://www.instagram.com/salonim.live/
          </Link>
        </Box>
      </Box>

      {/* Description */}
      <Typography sx={styles.description}>
        Salonim.Live connects people through unique, intimate events hosted in living rooms. Discover inspiring speakers, meet fascinating individuals, and enjoy authentic connections—all in a cozy, vibrant atmosphere.
      </Typography>

      {/* Privacy Policy Link */}
      <Link
        href="/privacy-policy"
        target="_blank"
        underline="none"
        sx={styles.privacyPolicyLink}
      >
        Privacy Policy
      </Link>
    </Box>
  );
};

// Styles for the component
const styles = {
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    textAlign: 'center',
    position: 'relative',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '150px',
    height: '150px',
    marginBottom: '10px',
  },
  instagramSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0px',
  },
  instagramIcon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  instagramLink: {
    fontSize: '14px',
    color: '#0000EE', // Changed to blue
    textDecoration: 'none',
    marginBottom: '0px',
  },
  description: {
    margin: '20px 0',
    fontSize: '16px',
    color: '#333',
  },
  privacyPolicyLink: {
    fontSize: '14px',
    color: '#0000EE', // Changed to blue
    textDecoration: 'none',
    marginTop: '20px',
  },
};

export default About;
