import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // To get parameters from the route
import {
    Box,
    Typography,
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Grid
} from '@mui/material'; // Material UI components for styling
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'; // Google Maps components
import { useAuth } from '../../context/AuthContext';
import { approveUserForEvent, getEventById } from '../../shared/services/eventServices';

// MyEvent component to display and manage event details
const MyEvent = () => {
    const { event_id } = useParams(); // Get event ID from the route parameters
    const [event, setEvent] = useState<any>(null); // State to store event details
    const [loading, setLoading] = useState<boolean>(true); // State to track loading status
    const [openModal, setOpenModal] = useState<boolean>(false); // State to control modal visibility
    const { user } = useAuth(); // Get user info from authentication context
    const [state, setState] = useState<string>('register'); // State to track some UI state (not used here)

    // Fetch event details when the component mounts or event_id changes
    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const eventData = await getEventById(event_id); // Fetch event data using the service
                setEvent(eventData); // Set fetched event data to state
            } catch (error) {
                console.error('Error fetching event details:', error); // Log errors
            }
            setLoading(false); // Set loading to false after fetching data
        };

        fetchEvent(); // Call the fetchEvent function
    }, [event_id]); // Dependency array ensures effect runs when event_id changes

    // Render a loading message if data is still loading
    if (loading) {
        return <Typography>Loading event details...</Typography>;
    }

    // Render a message if the event is not found
    if (!event) {
        return <Typography>Event not found</Typography>;
    }

    // Handler to close the approval modal
    const handleApproveModal = () => {
        setOpenModal(false); // Close the modal
    };

    // Handler to close any modal
    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal
    };

    // Function to handle guest approval
    async function handleApproveGuest(id: any, status: string): Promise<void> {
        const response: any = await approveUserForEvent(event.event_id, id, status); // Approve the user via service

        if (response) {
            // Update the guest's status in the local state
            event.guests.map((guest: any) => {
                if (guest.user_id === id) {
                    guest.status = status; // Update the guest status
                }
            });
        }
    }

    // Function to handle guest rejection (currently not implemented)
    function handleRejectGuest(id: any): void {
        // Logic to reject the guest
    }

    return (
        <div>
            {/* Event Details Section */}
            <Box>
                <Typography variant="h4">{event.event_name} Event</Typography> {/* Event name */}
                <Typography variant="subtitle1">{event.description || 'missing description'}</Typography> {/* Event description */}
            </Box>

            {/* Google Map Section */}
            <LoadScript googleMapsApiKey="AIzaSyCMplCIFs55tQnyFOfBjZnp79r8vDgqbmY"> {/* Load Google Maps API */}
                <GoogleMap
                    mapContainerStyle={containerStyle} // Set container style
                    center={{ lat: Number(event.location._lat), lng: Number(event.location._long) }} // Map center coordinates
                    zoom={10} // Map zoom level
                >
                    <Marker position={{ lat: Number(event.location._lat), lng: Number(event.location._long) }} /> {/* Event location marker */}
                </GoogleMap>
            </LoadScript>

            {/* Guest List Section */}
            <List>
                {event.guests.map((guest: any) => (
                    <ListItem key={guest.id} alignItems="flex-start"> {/* Render each guest */}
                        <Grid container direction="column" spacing={2}> {/* Container for guest details */}
                            <Grid item>
                                <Grid container alignItems="center"> {/* Guest avatar and basic info */}
                                    <Grid item>
                                        <ListItemAvatar>
                                            <Avatar src={guest.image} /> {/* Guest avatar */}
                                        </ListItemAvatar>
                                    </Grid>
                                    <Grid item>
                                        <ListItemText primary={guest.username} secondary={guest.email} /> {/* Guest name and email */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}> {/* Buttons to update guest status */}
                                    <Grid item>
                                        {/* Approve Button */}
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color={guest.status !== 'approve' ? 'success' : 'primary'}
                                            onClick={() => handleApproveGuest(guest.user_id, 'approved')}
                                        >
                                            Approve
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {/* Pending Button */}
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color={guest.status !== 'pending' ? 'primary' : 'secondary'}
                                            onClick={() => handleApproveGuest(guest.user_id, 'pending')}
                                        >
                                            Pending
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {/* Reject Button */}
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color={guest.status !== 'reject' ? 'primary' : 'error'}
                                            onClick={() => handleApproveGuest(guest.user_id, 'rejected')}
                                        >
                                            Reject
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

// Styles for the Google Map container
const containerStyle = {
    width: '100%',
    height: '200px',
};

export default MyEvent; // Export the component