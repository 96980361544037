// src/eventsService.js
import { auth, db } from './firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from 'firebase/storage';
import {
    collection,
    addDoc,
    getDocs,
    getDoc,
    setDoc,
    doc,
    updateDoc,
    deleteDoc,
    query,
    where,
    orderBy,
    getFirestore,
} from 'firebase/firestore';

// Reference to the 'events' collection
const userCollection = collection(db, 'users');


const storage = getStorage(); // Initialize Firebase Storage

export const getDownloadImageUrl = async (file: Blob | Uint8Array | ArrayBuffer) => {
    if (!file) return null;
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `images/temp/${(file as File).name}`);

    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(snapshot.ref);

        return downloadURL;
    } catch (error) {
        console.error('Error uploading file and getting download URL:', error);
        return null;
    }
};

export const uploadImageAndSaveToFirestore = async (userId: any, file: Blob | Uint8Array | ArrayBuffer) => {
    if (!file) return;

    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `images/${userId}/${(file as File).name}`);

    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Now update Firestore with the file's download URL
        const userDocRef = doc(db, `users/${userId}`);
        await updateDoc(userDocRef, {
            image: downloadURL, // Save the download URL in Firestore
        });
        console.log('File uploaded and Firestore updated successfully');
    } catch (error) {
        console.error('Error uploading file and saving to Firestore:', error);
    }
};

export const addSalonToUser = async (userId: any, salonDescription: string, location: string) => {
    try {
        const salonDocRef = await addDoc(collection(db, 'salons'), {
            userId: userId,
            description: salonDescription,
            location: location
        });
        console.log('Salon added to Firestore:', salonDocRef.id);

        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const salons = userDocSnap.data()?.salons || [];
            salons.push(salonDocRef.id);
            await updateDoc(userDocRef, {
                salons: salons
            });
            console.log('Salon added to user successfully');
        } else {
            console.error('No such user document!');
        }
    } catch (error) {
        console.error('Error adding salon to user:', error);
        throw error;
    }
};

export const addEventToUser = async (userId: any, eventId: string) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            const events = userDocSnap.data()?.events || [];
            
            // Check if the event already exists for the user
            const isEventAdded = events.some((event: any) => event.event_id === eventId);

            if (!isEventAdded) {
                const eventItem = {
                    event_id: eventId,
                    status: 'pending',
                };
                events.push(eventItem);

                // Update the document with the new event list
                await updateDoc(userDocRef, {
                    events: events
                });
                
                console.log('Event added to user successfully');
            } else {
                console.log('User already has this event');
            }
        } else {
            console.error('No such user document!');
        }
    } catch (error) {
        console.error('Error adding event to user:', error);
        throw error;
    }
};


// Function to add email document
export const sendEmail = async (userId: string, qrcode: any) => {
    try {

        const storage = getStorage();
        const storageRef = ref(storage, `qrcodes/userId/${Date.now()}-qrcode.png`);

        const file = await uploadString(storageRef, qrcode.split(',')[1], 'base64', { contentType: 'image/png' });

        // Upload the file to Firebase Storage
        const downloadURL = await getDownloadURL(storageRef);

        await addDoc(collection(db, "emails"), {
            to: "avihairing@gmail.com",
            subject: "You got approved to the event",
            text: "Congratulations! You have been invited to the event. Please choose your preferred location, time, and place.",
            html: `
            <html>
            <body>
            <h1>Congratulations!</h1>
            <p>You have been invited to the event. Please scan qa code for event details and payment.</p>
            <img src="${downloadURL}" alt="QR Code">
            </body>
            </html>
            `
        });
        console.log('Email document added to Firestore');
    } catch (error) {
        console.error('Error adding document: ', error);
    }
};

// Function to add email document
export const sendSMS = async (userPhoneNumber: string, qrcode: any) => {
    try {

        await addDoc(collection(db, "sms"), {
            to: '+972543260783',
            subject: "בזמן שאתה ישן לך אני סידרתי שליחת sms מהאפליקציה",
            text: "מקווה שאתה ישן טוב אחרי הארוחה",
        });
        console.log('Email document added to Firestore');
    } catch (error) {
        console.error('Error adding document: ', error);
    }
};


export const getUserEvents = async (userId: any) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const events = userDocSnap.data()?.events || [];
            return events;
        } else {
            console.error('No such user document!');
            return [];
        }
    } catch (error) {
        console.error('Error fetching user events:', error);
        return [];
    }
};

export const fetchUserByDocumentId = async (userId: string) => {
    try {

        const docRef = await doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching user document:', error);
        return null;
    }
};

export const setUserEmptyDoc = async (userId: string) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await setDoc(userDocRef, {}); // Add an empty user document
        console.log('Empty user document set successfully');
    } catch (error) {
        console.error('Error setting empty user document:', error);
        throw error;
    }
};

export const updateUserDocument = async (userId: string, data: any) => {
    try {
        const docRef = await doc(db, 'users', userId);
        const userDocSnap = await getDoc(docRef);
        // if(data.image){
        //     await uploadImageAndSaveToFirestore(userId, data.image);
        // }

        // delete data.image;
        
        if (!userDocSnap.exists()) {
            await setDoc(docRef, data); // Create a new document if it doesn't exist
        } else {
            await updateDoc(docRef, {
                ...data// Add the fcmToken to the data object
            });
        }

        // const fcmToken = await getFcmToken();  // Await the Promise here
        // if (fcmToken) {
        //     // Update the FCM token in Firestore
        //     await updateDoc(doc(db, 'users', userId), {
        //         fcmToken: fcmToken  // Pass the resolved token, not the Promise
        //     });
        //     console.log('Updated FCM token for user:', userId);
        //     console.log('User document updated successfully!');
        // }
        return await (await getDoc(docRef)).data();
    } catch (error) {

        console.error('Error updating user document:', error);
        throw error;
    }
};



export const getUserByID = async (id: any) => {
    try {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            try {
                const userDocRef = doc(db, 'users', userId); // Assuming 'users' is your collection
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    console.log('User Document:', userDocSnap.data());
                    return userDocSnap.data();
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user document:', error);
            }
        } else {
            console.error('No user is signed in.');
        }
    } catch (error) {
        console.error('Error fetching user by document:', error);
        throw error; // You can also handle this error differently if needed
    }
};


/**
 * Retrieves liver data by liver ID.
 *
 * This function fetches a document from the 'Livers' collection in the database
 * using the provided liver ID. If the document exists, it returns the data;
 * otherwise, it logs an error and returns null.
 *
 * @param {string} liverId - The ID of the liver document to retrieve.
 * @returns {Promise<Object | null>} A promise that resolves to the liver data if the document exists, or null if it does not.
 * @throws Will throw an error if there is an issue fetching the liver document.
 */
export const getLiverDataById = async (liverRef: any) => {
    try {
        const liverDocSnap = await getDoc(liverRef);
        if (liverDocSnap.exists()) {
            return liverDocSnap.data();
        } else {
            console.error('No such liver document!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching liver document:', error);
        throw error;
    }
};

export const getRandomLivers = async () => {
    try {
        const liversCollection = collection(db, 'Livers');
        const liversSnapshot = await getDocs(liversCollection);
        const livers = liversSnapshot.docs.map(doc => doc.data());

        // Shuffle the array to get random livers
        for (let i = livers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [livers[i], livers[j]] = [livers[j], livers[i]];
        }

        // Return the first 10 livers from the shuffled array
        return livers.slice(0, 14);
    } catch (error) {
        console.error('Error fetching random livers:', error);
        throw error;
    }
};





