import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    IconButton,
    TextField,
} from '@mui/material';
import { LocationOn, CalendarToday } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { addEventToUser, getLiverDataById, getUserEvents, updateUserDocument } from '../../shared/services/userService';
import CloseIcon from '@mui/icons-material/Close';
import { functions } from '../../shared/services/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { format } from 'date-fns';
import WhatsAppButton from '../../shared/components/WhatsappBtn';
import { getUserTicket } from '../../shared/services/transactionService';
import Ticket from './components/Ticket';
import { useNavigate } from 'react-router-dom'; // Use navigate for routing
import MessageModal from '../../shared/components/MessageModal';
import ImageSlider from '../../shared/components/ImageSlider';
import { isBefore } from 'date-fns'; // Import a helper from date-fns to compare dates
import PeopleJoined from '../../shared/components/PeopleJoined';
import { addUserToEvent, getEventById, removeUserFromEvent } from '../../shared/services/eventServices';


const EventDetail = () => {
    const { event_id } = useParams(); // Get event ID and isRegister from the route
    const location = useLocation(); // React Router hook for location
    const { isRegister = false } = location.state || {};
    const [event, setEvent] = useState<any>(null);
    const [liver, setLiverData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [afterRegisterModal, setAfterRegisterModal] = useState<boolean>(false);
    const { user } = useAuth();
    const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
    const [openPaymentModal, setPaymentModal] = useState<boolean>(false);
    const [approvedPaymentModal, setApprovePaymentModal] = useState<boolean>(false);
    const [state, setState] = useState<string>('register');
    const [cardNumber, setCardNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState<any>(null);
    const [userPaid, setUserPaid] = useState<any>(null);
    const navigate = useNavigate(); // React Router hook for navigation
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [messageModal, setMessageModal] = useState({
        open: false,
        header: "",
        message: "",
    });
    const handleEmailSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Add your email submission logic here
        try {
            updateUserDocument(user.user.uid, { email: email });
            user.user.email = email;
            setOpenEmailModal(false);
            handleOpenModal();
        }
        catch (error) {
            setOpenEmailModal(false);
            console.error('Error updating email:', error);
        }
    };

    const handleOpenModal = () => {
        if (!user) {

            const serializableEvent = {
                id: event.id,
                name: event.name,
                date: event.date,
                // Add other necessary serializable properties here
            };

            navigate('/verification', { state: { data: serializableEvent } });
            setOpenModal(true);
        } else {
            if (state === 'register' || state === 'canceled') {
                if (!user.user) {
                    navigate('/onboarding', { state: { data: { event_id: event.id, name: event.name, date: event.date } } });
                } else {
                    const userEmail = user.user.email;
                    if (!userEmail) {
                        setOpenEmailModal(true);
                        return;
                    }
                    addEventToUser(user.user.uid, event_id || '');
                    addUserToEvent(event_id || '', user);
                    setAfterRegisterModal(true);
                }
            } else if (state === 'pending') {
                setAfterRegisterModal(true);
            } else if (state === 'approved') {
                setAfterRegisterModal(true);
            }
        }
    };

    /**
     * Fetches event details and user-related data on component mount.
     */
    useEffect(() => {
        if (event_id && user && user.user) {
            const checkEventInUser = async () => {
                try {
                    const userEvents = await getUserEvents(user.user.uid); // Fetch user's events
                    const eventExists = userEvents.filter((event: any) => event.event_id === event_id);

                    if (eventExists.length > 0) {
                        setState(eventExists[0].status);

                        if (eventExists[0].status === 'register') {
                            setAfterRegisterModal(true);
                        }
                    }

                    if (state === 'pending') {
                        handleOpenModal();
                    }
                } catch (error) {
                    console.error('Error checking event in user:', error);
                }
            };

            const isUserPaid = async () => {
                try {
                    const userPaid: any = await getUserTicket(event_id, user.user.uid);
                    if (userPaid?.paid) {
                        setUserPaid(userPaid);
                        setState('paid');
                    }
                } catch (error) {
                    console.error('Error checking if user paid:', error);
                }
            };


            isUserPaid();
            checkEventInUser();
        }

        const fetchEvent = async () => {
            setLoading(true);
            try {
                const eventData = await getEventById(event_id); // Fetch the event by ID
                setEvent(eventData);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
            setLoading(false);

            if (isRegister && user && user.user) {
                addEventToUser(user.user.uid, event_id || '');
                addUserToEvent(event_id || '', user);
                setAfterRegisterModal(true);
            }

        };

        fetchEvent();
    }, [user]);

    useEffect(() => {

        const fetchLivers = async () => {
            console.log("Event data received", event);
            if (event) {
                console.log("Event data received:", event); // Log the entire event object
                const liverArray = [];
                for (const speaker of Array.isArray(event.livers) ? event.livers : []) {
                    try {
                        const liverData: any = await getLiverDataById(speaker); // Assuming getUserEvents fetches user data
                        const liver = {
                            image: liverData.speaker_image,
                            title: liverData.inspiring_topic,
                            description: "12-13 Mar 2024",
                            location: "New York, USA",
                            tag: liverData.topics
                        };
                        liverArray.push(liver);
                    } catch (error) {
                        console.error(`Error fetching data for speaker ${speaker.userId}:`, error);
                    }
                }
                /* if (liverArray.length === 1) {
                     liverArray.push(liverArray[0]);
                 }*/
                setLiverData(liverArray);
            }
        };

        fetchLivers();
    }, [event]);

    if (loading) {
        return <Typography></Typography>;
    }

    if (!event) {
        return <Typography>Event not found</Typography>;
    }

    const handleCancelRegistration = async () => {
        try {
            // Call your service to cancel the registration
            await removeUserFromEvent(event_id || '', user.user.uid,);
            setState('register'); // Reset state to "register"
            setMessageModal({
                open: true,
                header: "Registration Canceled",
                message: `Your event registration has been successfully canceled.
Thank you very much, and we hope to see you at future events! 😊`,
            });
        } catch (error) {
            console.error('Error canceling registration:', error);
            setMessageModal({
                open: true,
                header: "Error",
                message: "Failed to cancel registration. Please try again.",
            });
        }
    };

    const handlePaymentSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(true);
        setMessage(''); // Reset message

        try {
            // Call mock payment function
            const mockPayment = httpsCallable(functions, 'mockPayment');

            const result: any = await mockPayment({
                user_id: user.user.uid,
                event_id: event_id,
                cardNumber: '1234-5678-9012-3456',
                amount: 100,
            });

            // Handle result

            // Display the result message
            setMessage(result.data);
            setPaymentModal(false);
            setApprovePaymentModal(true);
        } catch (error) {
            console.error('Error processing payment:', error);
            setMessage('An error occurred during payment processing.');
        } finally {
            setLoading(false);
        }
    };

    const handleDonePayment = () => {
        // setOpenApproveModal(true);
    };

    const handleDoneRequest = () => {
        setAfterRegisterModal(false);
        if (state === 'register' || state === 'canceled') {
            setState('pending');
        }
    };

    const handleGetTicket = () => {
        setOpenApproveModal(true);
    };

    const handleApproveModal = () => {
        setOpenApproveModal(false);
        setApprovePaymentModal(false);
    };


    const isEventInPast = event?.event_date?.seconds
        ? isBefore(new Date(event.event_date.seconds * 1000), new Date())
        : isBefore(new Date(event.event_date), new Date());


    return (
        <Box p={2} style={{ direction: 'ltr', position: 'relative', paddingBottom: '70px', padding: '0px 0px 70px 0px' }}>
            {/* Header Image and Menu Bar */}
            <Box position="relative" mb={2} style={{ padding: '16px', }}>
                <img
                    src={event.event_image}
                    alt="Event"
                    style={{ minHeight: '200px', width: '100%', borderRadius: '30px', height: '350px', objectFit: 'cover' }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    position="absolute"
                    top="35px"
                    left="35px"
                    right="35px"
                >
                    {/* <Button
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            backdropFilter: 'blur(2.5px)',
                            borderRadius: '50px',
                            padding: '15px',
                            minWidth: '44px',
                            height: '44px',
                            color: '#fff',
                        }}
                    >
                        <Favorite />
                    </Button> */}
                </Box>
            </Box>

            {/* Tags */}
            {/* <Box display="flex" justifyContent="space-between" mb={2}> */}
            {/* <Button
                    sx={{
                        backgroundColor: '#5D9BFC',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '8px 12px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                        fontSize: '11px',
                    }}
                >
                    Theatre
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#5D9BFC',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '8px 12px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                        fontSize: '11px',
                    }}
                >
                    Invite
                </Button> */}
            {/* <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9px',
                        color: '#AAAAAA',
                    }}
                >
                    {`Per Person `}
                    <Typography variant="h6" display="inline" fontWeight="bold" color="text.primary">
                        ${event.price}
                    </Typography>
                </Typography>
            </Box> */}

            <Box style={{ padding: '16px' }}>
                {/* Event Title */}
                <Typography
                    variant="h5"
                    fontWeight="700"
                    sx={{
                        fontSize: '22px',
                        marginBottom: '20px',
                    }}
                >
                    {event.event_name}
                </Typography>

                {/* Divider */}
                <Box sx={{ borderBottom: '1px solid #EEEEEE', marginY: '16px' }} />

                {/* Date */}
                <Box display="flex" alignItems="center" mb={3}>
                    <Box
                        sx={{
                            backgroundColor: 'rgba(93, 155, 252, 0.15)',
                            borderRadius: '30px',
                            padding: '9px 10px',
                            marginRight: '10px',
                        }}
                    >
                        <CalendarToday sx={{ color: '#5D9BFC' }} />
                    </Box>
                    <Box>
                        <Typography sx={{ fontFamily: 'Arialsans-serif', fontSize: '13px', color: '#000000', marginBottom: '0px' }}>
                            {event.event_date?.seconds ? (
                                format(new Date(event.event_date.seconds * 1000), 'MM/dd/yyyy')
                            ) : event.event_date ? (
                                format(new Date(event.event_date), 'dd/MM/yyyy')
                            ) : 'Invalid date'}
                        </Typography>
                    </Box>
                </Box>

                {/* Location */}
                <Box display="flex" alignItems="center" mb={3}>
                    <Box
                        sx={{
                            backgroundColor: 'rgba(93, 155, 252, 0.15)',
                            borderRadius: '30px',
                            padding: '9px 10px',
                            marginRight: '10px',
                        }}
                    >
                        <LocationOn sx={{ color: '#5D9BFC' }} />
                    </Box>
                    <Box>
                        <Typography sx={{ fontFamily: 'Arialsans-serif', fontSize: '13px', color: '#000000', marginBottom: '0px' }}>
                            {event.locationName}, IL
                        </Typography>
                        <Typography sx={{ fontFamily: 'Arialsans-serif', fontSize: '11px', color: '#AAAAAA', marginBottom: '0px' }}>
                            {event.locationDetails}
                        </Typography>
                    </Box>
                </Box>
                <PeopleJoined guests={event.guests} />
            </Box>

            {isEventInPast ? (
                <Box display="flex" gap={2} mb={3} justifyContent="center">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled
                        sx={{
                            borderRadius: '50px',
                            padding: '10px 30px',
                            fontSize: '11px',
                            backgroundColor: '#CCCCCC', // Optional: Grey background for disabled button
                            color: '#FFFFFF', // Optional: White text for better contrast
                        }}
                    >
                        The event has ended
                    </Button>
                </Box>
            ) : (
                <Box display="flex" gap={2} mb={3} justifyContent="center">
                    {state === 'paid' && (
                        <Button
                            sx={{
                                backgroundColor: '#5D9BFC',
                                color: '#fff',
                                borderRadius: '50px',
                                padding: '11px 15px',
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '11px',
                                fontWeight: '600',
                                marginBottom: '0px'
                            }}
                            onClick={handleGetTicket}
                        >
                            Show Ticket
                        </Button>
                    )}
                    {state !== 'paid' && (
                        <Button
                            variant="contained"
                            color={state === 'pending' ? 'secondary' : state === 'approved' ? 'success' : 'primary'}
                            size="large"
                            sx={{
                                borderRadius: '50px',
                                padding: state === 'register' || state === 'canceled' ? '20px 80px' : '5px 30px',
                                fontSize: '11px',
                                margin: '10px'
                            }}
                            onClick={handleOpenModal}
                            disabled={event.status === 'sold'}
                        >
                            {event.status === 'sold' ? 'SOLD OUT' : state === 'pending' ? 'Pending approval' : state === 'approved' ? 'APPROVED' : 'REQUEST TO JOIN'}
                        </Button>
                    )}
                    {/* Cancel Registration Button */}
                    <Button
                        variant="outlined"
                        color="error"
                        size="large"
                        sx={{ borderRadius: '50px', padding: '5px 30px', fontSize: '11px', margin: '10px' }}
                        onClick={handleCancelRegistration}
                        style={{ display: state !== 'register' && state !== 'canceled' && state !== 'paid' ? 'inline-flex' : 'none' }}
                    >
                        Cancel Registration
                    </Button>
                </Box>
            )}

            {/* Description */}
            <Box style={{ padding: '16px' }}>
                {/* Divider */}
                <Box sx={{ borderBottom: '1px solid #EEEEEE', marginY: '20px' }} />

                <Typography
                    variant="body2"
                    sx={{ direction: /[\u0590-\u05FF]/.test(event.description) ? 'rtl' : 'ltr', fontFamily: 'Arialsans-serif', fontSize: '13px', lineHeight: '20px', color: '#AAAAAA' }}
                    dangerouslySetInnerHTML={{ __html: event.description }}
                />
            </Box>

            <Typography
                variant="h5"
                fontWeight="700"
                sx={{
                    paddingLeft: '16px',
                    fontSize: '22px',
                }}
            >
                LIVERS
            </Typography>

            {/* People Joined */}
            {liver && liver.length > 0 && <ImageSlider imageCards={liver} />}

            {afterRegisterModal && (
                <MessageModal
                    open={afterRegisterModal}
                    onClose={handleDoneRequest}
                    header={`Thanks for signing up! 🙌 `}
                    message={
                        state === 'register' || state === 'pendiing' || state === 'canceled'
                            ? 'Your application for the event has been received. We are currently reviewing your submission and will notify you of approval via WhatsApp. Stay tuned! 😊'
                            : state === 'pending' ? `We are still reviewing your application.
You will receive an update no later than 2 days before the event.
The status of your approval will be updated here on the app.
Thank you for your patience! 😊` : ''
                    }
                    onActionClick={handleDoneRequest}
                />
            )}

            {messageModal.open && (
                <MessageModal
                    open={messageModal.open}
                    onClose={() => setMessageModal({ open: false, header: "", message: "" })}
                    header={messageModal.header}
                    message={messageModal.message}
                    onActionClick={() => setMessageModal({ open: false, header: "", message: "" })}
                />
            )}

            {openApproveModal && (
                <Modal open={openApproveModal} onClose={handleApproveModal}>
                    <Box sx={{
                        width: 'calc(100% - 32px)',
                        maxWidth: 400,
                        maxHeight: '60%',
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        outline: 'none',
                    }}>
                        <Ticket event={event} user={user} booking={userPaid}></Ticket>
                    </Box>
                </Modal>
            )}

            {openEmailModal && (
                <Modal open={openEmailModal} onClose={() => setOpenEmailModal(false)}>
                    <Box component="form" onSubmit={handleEmailSubmit} sx={{
                        backgroundColor: 'white',
                        width: 'calc(80% - 32px)',
                        maxWidth: 400,
                        maxHeight: '60%',
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        outline: 'none',
                    }}>
                        <Typography sx={{ mb: 2 }}>
                            Please enter your email to continune.
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                            helperText={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? 'Please enter a valid email address' : ''}
                            sx={{ mb: 2 }}
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            sx={{ float: 'right' }}
                            disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                        >
                            Submit
                        </Button>
                    </Box>
                </Modal>
            )}

            {openPaymentModal && (
                <Modal open={openPaymentModal} onClose={handleDonePayment}>
                    <Box sx={{
                        // width: 'calc(100% - 32px)',
                        backgroundColor: 'white',
                        whiteSpace: 'nowrap',
                        maxHeight: '60%',
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        outline: 'none',
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => setPaymentModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <WhatsAppButton event={event}></WhatsAppButton>
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default EventDetail;