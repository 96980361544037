// src/eventsService.js
import { auth, db } from './firebaseConfig';
import {
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
} from 'firebase/firestore';

export const checkIfUserPaidForEvent = async (eventID: string, userId: string) => {
    try {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            try {
                const userDocRef = doc(db, 'transactions', `${userId}_${eventID}`); // Assuming 'users' is your collection
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    console.log('Transaction Document:', userDocSnap.data());
                    return userDocSnap.data();
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user document:', error);
            }
        } else {
            console.error('No user is signed in.');
        }
    } catch (error) {
        console.error('Error fetching user by document:', error);
        throw error; // You can also handle this error differently if needed
    }
};

export const getUserTicket = async (eventID: string, userId: string) => {
    try {
        if (userId) {
            try {
                const ticketQuery = query(
                    collection(db, 'tickets'),
                    where('eventId', '==', eventID),
                    where('userId', '==', userId)
                );
                const ticketQuerySnapshot = await getDocs(ticketQuery);

                if (!ticketQuerySnapshot.empty) {
                    const ticketDocSnap = ticketQuerySnapshot.docs[0];
                    console.log('Ticket Document:', ticketDocSnap.data());
                    return ticketDocSnap.data();
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching ticket document:', error);
            }
        } else {
            console.error('No user is signed in.');
        }
    } catch (error) {
        console.error('Error fetching ticket by document:', error);
        throw error;
    }
};

