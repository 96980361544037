import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import logo from '../../../assets/images/logo_27.png'; // Replace with your actual logo path
import { Link } from 'react-router-dom';

const StickyHeader = () => {
    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: '#ffffff',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 1100, // Ensures it stays on top
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Centers the content
                }}
            >
                {/* Logo Container */}
                <Link to="/events">
                    <Box
                        component="img"
                        src={logo}
                        alt="Company Logo"
                        sx={{
                            height: 30,
                            width: 'auto',
                        }}
                    />
                </Link>
            </Toolbar>
        </AppBar>
    );
};

export default StickyHeader;
