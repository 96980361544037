import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { LocationOn } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { getRandomLivers } from '../../shared/services/userService';
import PeopleJoined from '../../shared/components/PeopleJoined';

interface EventListProps {
    events: any[];
    tab: string;
    type: string;
}

interface EventCardProps {
    event: any;
    type: string;
    guests: any;
}

/**
 * Formats the date into Hebrew format: "ביום רביעי 13.10.24 בשעה 20:15 בנמל תל אביב"
 */
const formatEventDetails = (eventDate: string | { seconds: number }, locationName: string) => {
    const days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
    const date = typeof eventDate === 'string' ? new Date(eventDate) : new Date(eventDate.seconds * 1000);

    const day = days[date.getDay()];
    const dayNum = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2); // שנתיים בלבד
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return ` יום ${day} ${dayNum}.${month}.${year}\nשעה ${hours}:${minutes} ב${locationName}`;
};



/**
 * EventCard component renders a card for each event in the Salonim Live application.
 */
const EventCard = ({ event, type, guests }: EventCardProps) => {
    return (
        <Link
            to={`${type === 'admin' ? '/events/managment' : '/events'}/${event.id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="15px"
                gap="15px"
                width="calc(100% - 30px)"
                borderRadius="15px"
                mb={2}
                style={{
                    background: '#F9FAFB', // Soft grey background
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
                    margin: '5px 5px',
                }}
            >
                {/* Left Side: Image */}
                <Avatar
                    variant="rounded"
                    src={event.event_image}
                    alt="Event"
                    style={{
                        width: '120px',
                        height: '130px',
                        objectFit: 'cover',
                        borderRadius: '15px',
                    }}
                />

                {/* Right Side: Content */}
                <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
                    {/* Event Details: Date, Time, and Location */}
                    <Typography
    sx={{
        fontFamily: "'Heebo', sans-serif !important",
        fontSize: '16px !important',
        fontWeight: '400 !important',
        color: '#37474F !important',
        textAlign: 'right',
        whiteSpace: 'pre-line', // הכרחי כדי להציג שורות חדשות
    }}
>
    {formatEventDetails(event.event_date, event.locationName)}
</Typography>


                    {/* Guests Section */}
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        marginTop="12px"
                        paddingBottom="5px"
                    >
                        <PeopleJoined guests={event.guests} />
                    </Box>
                </Box>
            </Box>
        </Link>
    );
};

const EventList = ({ events, tab, type }: EventListProps) => {
    const { user } = useAuth();
    const [futureEvents, setFutureEvents] = useState<any[]>([]);
    const [pastEvents, setPastEvents] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [randomLivers, setRandomLivers] = useState<any[]>([]);

    useEffect(() => {
        const loadEvents = async () => {
            setLoading(true); // Start loading
            const now = new Date();

            // Simulate a network request delay
            await new Promise(resolve => setTimeout(resolve, 1000));

            const sortedEvents = [...events].sort((a, b) => {
                const dateA = new Date(a.event_date).getTime();
                const dateB = new Date(b.event_date).getTime();
                return dateB - dateA;
            });

            const future = sortedEvents.filter(event => new Date(event.event_date) >= now).sort((a, b) => {
                const dateA = new Date(a.event_date).getTime();
                const dateB = new Date(b.event_date).getTime();
                return dateA - dateB;
            });
            const past = sortedEvents.filter(event => new Date(event.event_date) < now);

            setFutureEvents(future);
            setPastEvents(past);
            setLoading(false); // Stop loading
        };

        const fetchRandomLivers = async () => {
            setLoading(true); // Stop loading

            const data = await getRandomLivers();
            setRandomLivers(data);
            setLoading(false); // Stop loading
        };

        fetchRandomLivers();
        loadEvents();
    }, [events]);

    if (loading || futureEvents.length === 0) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            {/* Future Events */}
            <Typography
                variant="h6"
                sx={{
                    margin: '15px !important',
                    fontSize: '18px !important',
                    fontFamily: "'Poppins', sans-serif !important",
                    color: '#2E7D32',
                }}
            >
                אירועים קרובים ↑
            </Typography>

            {futureEvents.map((event, index) => (
                <EventCard
                    key={index}
                    event={event}
                    type={type}
                    guests={randomLivers}
                />
            ))}

            {/* Past Events */}
            <Typography
                variant="h6"
                sx={{
                    margin: '15px !important',
                    fontSize: '18px !important',
                    fontFamily: "'Poppins', sans-serif !important",
                    color: '#B71C1C',
                }}
            >
                אירועים קודמים ↓
            </Typography>

            {pastEvents.length > 0 ? (
                pastEvents.map((event, index) => (
                    <EventCard
                        key={index}
                        event={event}
                        type={type}
                        guests={randomLivers}
                    />
                ))
            ) : (
                <Typography>אין אירועים קודמים.</Typography>
            )}
        </Box>
    );
};

export default EventList;
