import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import GenderScreen from './screens/GenderScreen';
import IntroScreen from './screens/IntroScreens';
import PurposeScreen from './screens/PurposeSceen';
import BadgeSelectionScreen from './screens/BadgeSelectionScreen';
import ImageUploadScreen from './screens/ImageUploadScreen';
import InfoScreen from './screens/InfoScreen';
import { updateUserDocument } from '../../../shared/services/userService';
import { useAuth } from '../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const Wizard = () => {
    const location = useLocation();
    const { id, name, date } = location.state?.data || {};
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        firstName: user.multiFactor?.user.displayName || '', // facebook default on display user if exists
        birthday: { day: '', month: '', year: '' },
        phoneNumber: '',
        gender: '',
        purpose: [] as string[],
        badges: [] as string[],
        image: user.multiFactor?.user.photoURL || '',// facebook default on photo user if exists
        bio: '',
        socialMediaUrl: user.multiFactor?.user.displayName || '', // facebook default on display user if exists
        occupation: ''

    });

    // Restore state from localStorage on component mount
    useEffect(() => {
        const savedStep = localStorage.getItem('wizardStep');
        const savedFormData = localStorage.getItem('wizardFormData');

        if (savedStep !== null) {
            setStep(JSON.parse(savedStep)); // Restore step
        }

        if (savedFormData !== null) {
            setFormData(JSON.parse(savedFormData)); // Restore form data
        }
    }, []);

    // Save current step and form data to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('wizardStep', JSON.stringify(step));
        localStorage.setItem('wizardFormData', JSON.stringify(formData));
    }, [step, formData]);

    const updateFormData = (field: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const registerUser = async () => {
        setLoading(true);
        try {
            if (user.multiFactor?.user.phoneNumber) {
                formData.phoneNumber = user.multiFactor.user.phoneNumber;
            }

            const response = await updateUserDocument(user.multiFactor.user.uid, formData);
            updateUser(response);

            // Clear localStorage after successful registration
            localStorage.removeItem('wizardStep');
            localStorage.removeItem('wizardFormData');
            if (id) {
                navigate(`/events/${id}`, { state: { isRegister: true } });
            } else {
                navigate('/events');
            }
        } catch (error) {
            console.error('Error registering user:', error);
        } finally {
            setLoading(false);
        }
    };

    const steps = [
        <IntroScreen
            onNext={() => setStep(step + 1)}
            setFirstName={(value) => updateFormData('firstName', value)}
            setBirthday={(value) => updateFormData('birthday', value)}
            firstName={formData.firstName}
            birthday={formData.birthday}
        />,
        <GenderScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setGender={(value) => updateFormData('gender', value)}
            gender={formData.gender}
            name={formData.firstName}
        />,
        <PurposeScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setPurpose={(value) => updateFormData('purpose', value)}
            selectedPurpose={formData.purpose}
        />,
        <BadgeSelectionScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setBadges={(value) => updateFormData('badges', value)}
            selectedBadges={formData.badges || []}
        />,
        <ImageUploadScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            onImageUpload={(value) => updateFormData('image', value)}
            uploadedImage={formData.image}
        />,
        <InfoScreen
            onNext={registerUser}
            onBack={() => setStep(step - 1)}
            onUpdate={(field, value) => updateFormData(field, value)}
            bio={formData.bio}
            occupation={formData.occupation}
            socialMediaUrl={formData.socialMediaUrl}
        />,
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowX: 'hidden',
                position: 'relative',
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                steps[step]
            )}
        </Box>
    );
};

export default Wizard;