import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { signOut } from 'firebase/auth'; // Import Firebase signOut
import { auth } from '../../shared/services/firebaseConfig';

interface User {
    firstName: string;
    lastName: string;
    role: string;
    location: string;
    image: string;
    birthday: any;
}

interface ProfileHeaderProps {
    user: User;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ user }) => {
    const [age, setAge] = useState('');

    useEffect(() => {
        const calculateAge = (birthday: { day: number; month: number; year: number }) => {
            const birthDate = new Date(birthday.year, birthday.month - 1, birthday.day);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };

        if (user.birthday) {
            setAge(calculateAge(user.birthday).toString());
        }
    }, [user.birthday]);

    const handleLogout = async () => {
        try {
            await signOut(auth); // Firebase logout
            alert('Successfully logged out');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '320px',
                backgroundColor: '#000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                overflow: 'hidden',
            }}
        >
            {/* Background Image */}
            <img
                src={user.image}
                alt={`${user.firstName}`}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 0,
                }}
            />

            {/* Dark Overlay */}
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.2)',
                    zIndex: 1,
                }}
            />

            {/* Logout Button */}
            <Button
                onClick={handleLogout}
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '24px',
                    padding: '8px 16px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    zIndex: 2,
                    '&:hover': {
                        backgroundColor: '#f7f7f7',
                    },
                }}
            >
                Logout
            </Button>

            {/* Profile Details */}
            <Box
                sx={{
                    zIndex: 2,
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                {/* First Name Badge */}
                <Typography
                    sx={{
                        position: 'absolute',
                        bottom: '25px',
                        left: '16px',
                        backgroundColor: '#fff',
                        color: '#000',
                        borderRadius: '12px',
                        padding: '4px 8px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        zIndex: 2,
                    }}
                >
                    {user.firstName}
                </Typography>

                {/* Role */}
                <Typography sx={{ fontSize: '16px', fontWeight: '400', marginTop: '32px' }}>
                    {user.role}
                </Typography>

                {/* Age */}
                <Typography
                    sx={{
                        position: 'absolute',
                        bottom: '4px',
                        fontSize: '16px',
                        fontWeight: '300',
                        left: '20px',
                        color: '#fff',
                        marginTop: '4px',
                    }}
                >
                    {age}
                </Typography>

                {/* Location */}
                <Typography sx={{ fontSize: '14px', fontWeight: '300', marginTop: '2px' }}>
                    {user.location}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProfileHeader;