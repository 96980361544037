import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../shared/services/firebaseConfig';
import { fetchUserByDocumentId } from '../shared/services/userService';

// Define the shape of your user object (based on Firebase auth types)
interface AuthContextProps {
    user: any | null;
    loading: boolean;
    logout: () => Promise<void>;  // Expose the logout function
    updateUser: (updatedUser: any) => Promise<void>;  // Expose the logout function
    
}

// Create the context with a default value
const AuthContext = createContext<AuthContextProps>({
    user: null,
    loading: true,
    logout: async () => { },  // Default implementation (no-op)
    updateUser: async () => { },  // Default implementation (no-op)
});

// Create a custom hook for using the AuthContext in any component
export const useAuth = () => useContext(AuthContext);

// Provider component to wrap around your app
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const salonimUser = await fetchUserByDocumentId(user.uid);
                    
                    if (salonimUser) {
                        setUser({ ...user.multiFactor, ...salonimUser });
                        console.log('user');
                    } else {
                        setUser(user);
                        console.log('no salonim user');
                    }
                } catch (error) {
                    console.error('Error fetching user:', error);
                }
            } else {
                setUser(null);  // Set user to null when no user is authenticated
                console.log('no user');
            }
            setLoading(false);  // Mark loading as complete
        });

        return () => unsubscribe();  // Cleanup
    }, []);

    // Logout function that signs out the user and clears the state
    const logout = async () => {
        await auth.signOut();  // Firebase sign-out
        setUser(null);  // Clear user in the context
    };

    const updateUser = async (updatedUser: any) => {
        console.log({...user.multiFactor, ...updatedUser});
        setUser({...user.multiFactor, ...updatedUser});  // Clear user in the context
    };

    return (
        <AuthContext.Provider value={{ user, loading, logout, updateUser }}>
            {children}
        </AuthContext.Provider>
    );
};
