import React from 'react';
import { Box, Typography, Button, Chip, Avatar, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileHeader from './ProfileHeader';

interface User {
    firstName: string;
    age: number;
    purpose: string[];
    socialMediaUrl: string;
    occupation: string;
    birthday: any;
    bio: string;
    badges: string[];
    image: string;
    gender: string;
    email: string;
}

interface ProfilePageProps {
    user: User;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }) => {
    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: '#fff',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column', // Ensures vertical layout
                position: 'relative', // Allows sticky child elements to position relative to this container
            }}
        >

            <Box
                sx={{
                    marginBottom: '24px',
                }}
            >
                <ProfileHeader
                    user={{
                        firstName: user.firstName,
                        image: user.image,
                        birthday: user.birthday,
                        lastName: '',
                        role: '',
                        location: ''
                    }}
                />
            </Box>

            {/* Email Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#f7f7f7', // Light gray background similar to other sections
                    borderRadius: '8px',
                    marginBottom: '16px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    Contact Information
                </Typography>

                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '14px',
                        marginBottom: '0px',
                        wordBreak: 'break-word', // Ensures long email addresses wrap properly
                    }}
                >
                    {user.email || 'No email provided'}
                </Typography>
            </Box>

            {/* Social Networks Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#f7f7f7', // Light gray background
                    borderRadius: '8px',
                    marginBottom: '16px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    About me
                </Typography>
                <Box
                    sx={{
                        padding: '16px',
                        backgroundColor: '#f7f7f7', // Light gray background
                        borderRadius: '8px',
                        marginBottom: '16px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#000',
                            fontSize: '14px',
                        }}
                    >
                        <Box
                            sx={{
                                direction: /[\u0590-\u05FF]/.test(user.bio) ? 'rtl' : 'ltr',
                            }}
                        >
                            {user.bio}
                        </Box>
                    </Typography>
                </Box>
                {/* <Box
                    sx={{
                        display: 'flex',
                        gap: '12px',
                    }}
                >
                    <IconButton
                        href="https://instagram.com"
                        target="_blank"
                        sx={{
                            backgroundColor: '#e0e0e0', // Light gray button background
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src="/path/to/instagram-icon.png" // Replace with Instagram icon path
                            alt="Instagram"
                            sx={{ width: '24px', height: '24px' }}
                        />
                    </IconButton>

                    <IconButton
                        href="https://linkedin.com"
                        target="_blank"
                        sx={{
                            backgroundColor: '#e0e0e0', // Light gray button background
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src="/path/to/linkedin-icon.png" // Replace with LinkedIn icon path
                            alt="LinkedIn"
                            sx={{ width: '24px', height: '24px' }}
                        />
                    </IconButton>
                </Box> */}
            </Box>

            {/* Purpose Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#f7f7f7', // Light gray background similar to the screenshot
                    borderRadius: '8px',
                    marginBottom: '16px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    Values I cherish
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '12px', // Slightly larger gap for a more modern feel
                    }}
                >
                    {user.badges && user.badges.length > 0 ? (
                        user.badges.map((badge, index) => (
                            <Chip
                                key={index}
                                label={badge}
                                sx={{
                                    border: '1px solid #000', // Thin black border for outline
                                    color: '#000',
                                    backgroundColor: '#fff',
                                    borderRadius: '20px',
                                    padding: '4px 12px',
                                    textTransform: 'capitalize',
                                    fontSize: '14px',
                                }}
                            />
                        ))
                    ) : (
                        <Typography
                            sx={{
                                color: '#000',
                                fontSize: '14px',
                            }}
                        >
                            No badges available
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* Badges Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#f7f7f7', // Light gray background similar to the screenshot
                    borderRadius: '8px',
                    marginBottom: '16px',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    {`${user.firstName} goals`}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '12px', // Slightly larger gap for a more modern feel
                    }}
                >
                    {user.purpose && user.purpose.length > 0 ? (
                        user.purpose.map((purpose, index) => (
                            <Chip
                                key={index}
                                label={purpose}
                                sx={{
                                    border: '1px solid #000', // Thin black border for outline
                                    color: '#000',
                                    backgroundColor: '#fff',
                                    borderRadius: '20px',
                                    padding: '4px 12px',
                                    textTransform: 'capitalize',
                                    fontSize: '14px',
                                }}
                            />
                        ))
                    ) : (
                        <Typography
                            sx={{
                                color: '#000',
                                fontSize: '14px',
                            }}
                        >
                            No purposes available
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* Spacer to push button to the bottom */}
            <Box sx={{ flexGrow: 1 }} />

            {/* Apply as Liver Button */}
            {/* <Button
                variant="contained"
                fullWidth
                sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    borderRadius: '16px',
                    textTransform: 'capitalize',
                    padding: '12px 0',
                    position: 'sticky', // Sticks to the bottom of its parent container
                    bottom: '90px',
                }}
            >
                APPLY AS LIVER
            </Button> */}
        </Box>
    );
};

export default ProfilePage;