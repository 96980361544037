import React from 'react';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

// Define types for props
interface EventProps {
    event_image: string;
  event_name: string;
  locationName: string;
  hall: string;
  event_date : Timestamp;
}

interface UserProps {
  name: string;
}

interface BookingProps {
  seat: string;
  date: string;
  time: string;
  paymentAuth: string;
  paidPrice: number;
  qrCode: string;
}

interface TicketProps {
  event: EventProps;
  user: UserProps;
  booking: BookingProps;
}

const Ticket: React.FC<TicketProps> = ({ event, user, booking }) => {
  const styles = {
    container: {
      backgroundColor: '#f8f9fa',
      padding: '20px',
      borderRadius: '15px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      margin: '0 auto',
      fontFamily: 'Arialsans-serif, sans-serif',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    backButton: {
      fontSize: '20px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    eventInfo: {
      display: 'flex',
      marginBottom: '20px',
    },
    eventImage: {
      width: '80px',
      height: '80px',
      borderRadius: '10px',
      marginRight: '15px',
    },
    eventDetails: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    eventLabel: {
      fontSize: '12px',
      color: '#007bff',
      marginBottom: '5px',
    },
    eventTitle: {
      margin : '0px',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    eventLocation: {
      margin : '0px',
      fontSize: '14px',
      color: '#666',
    },
    userDetails: {
      marginBottom: '20px',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#666',
    },
    value: {
      fontSize: '14px',
      color: '#333',
    },
    copyButton: {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      color: '#007bff',
      fontSize: '14px',
      padding: '0',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: '1px dashed #ccc',
      paddingTop: '10px',
    },
    priceLabel: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#333',
    },
    priceValue: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#007bff',
    },
  };

  return (
    <div style={styles.container}>
    <div style={styles.header}>
      <button style={styles.backButton} onClick={() => window.history.back()}>←</button>
      <h2 style={styles.title}>TICKET</h2>
    </div>

      <div style={styles.eventInfo}>
        <img src={event.event_image} alt={event.event_name} style={styles.eventImage} />
        <div style={styles.eventDetails}>
          <span style={styles.eventLabel}>Event</span>
          <h3 style={styles.eventTitle}>{event.event_name}</h3>
          <p style={styles.eventLocation}>{event.locationName}</p>
        </div>
      </div>

      <div style={styles.userDetails}>
        {/* <div style={styles.row}>
          <span style={styles.label}>Name</span>
          <span style={styles.value}>{user.name}</span>
        </div> */}
        <div style={styles.row}>
          <span style={styles.label}>Event Hall</span>
          <span style={styles.value}>{event.locationName}</span>
        </div>
        <div style={styles.row}>
          <span style={styles.label}>Seat</span>
          <span style={styles.value}>Salon</span>
        </div>
        <div style={styles.row}>
          <span style={styles.label}>Date</span>
          <span style={styles.value}>  {event.event_date?.seconds ? (
                            format(new Date(event.event_date.seconds * 1000), 'MM/dd/yyyy')
                        ) : event.event_date ? (
                            format(new Date(event.event_date as any), 'dd/MM/yyyy')
                        ) : 'Invalid date'}</span>
        </div>
        {/* <div style={styles.row}>
          <span style={styles.label}>Time</span>
          <span style={styles.value}>{booking.time}</span>
        </div> */}
        <div style={styles.row}>
          <span style={styles.label}>Booking ID</span>
          <span style={styles.value}>paid by phone</span>
          <button style={styles.copyButton}>Copy</button>
        </div>
      </div>

      <div style={styles.footer}>
        <span style={styles.priceLabel}>Price</span>
        <span style={styles.priceValue}>₪{booking.paidPrice}</span>
      </div>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/salonim-29dcf.appspot.com/o/qrCodeData%2FOJw15mkLAuVesrQVDmrksoMOUgr2%2F1731343939670-qrcode.png?alt=media&token=23dbe433-f00b-45fd-ba34-fad83942dad6" alt="QR Code" style={{ width: '150px', height: '150px' }} />
      </div>
    </div>
  );
};

export default Ticket;
