import React, { useState } from 'react';
import { Box, Typography, IconButton, CircularProgress, Button } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Cropper from 'react-easy-crop';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

interface ImageUploadScreenProps {
    onBack: () => void; // Callback for when the user proceeds to the next step
    onNext: () => void; // Callback for when the user proceeds to the next step
    onImageUpload: (image: string | null) => void; // Callback for when an image is uploaded
    uploadedImage: string | null; // URL or base64 string of the uploaded image
}

const ImageUploadScreen: React.FC<ImageUploadScreenProps> = ({ onNext, onBack, onImageUpload, uploadedImage }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null); // State to store the source of the image to be cropped
    const [loading, setLoading] = useState(false); // State to manage loading state during image upload
    const [crop, setCrop] = useState({ x: 0, y: 0 }); // State to manage the crop position
    const [zoom, setZoom] = useState(1); // State to manage the zoom level
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null); // State to store the cropped area pixels
    
    // Handle image file selection
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string); // Set the image source for cropping
                if (reader.result) {
                    const storage = getStorage();
                    const storageRef = ref(storage, `images/${Date.now()}.jpg`);

                    const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
                    uploadBytes(storageRef, blob).then(async (snapshot) => {
                        const downloadURL = await getDownloadURL(snapshot.ref);
                        onImageUpload(downloadURL); // Call the callback with the uploaded image URL
                    });
                }
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    // Handle crop completion
    const handleCropComplete = (_: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels); // Set the cropped area pixels
    };

    // Handle saving the cropped image
    const handleSaveCroppedImage = async () => {
        if (!imageSrc || !croppedAreaPixels) return;
        setLoading(true);
        try {
            // Convert the cropped area into a blob
            const response = await fetch(imageSrc);
            const blob = await response.blob();
            const canvas = document.createElement('canvas');
            const image = new Image();

            image.onload = () => {
                const { width, height, x, y } = croppedAreaPixels;
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(image, x, y, width, height, 0, 0, width, height);

                canvas.toBlob(async (croppedBlob) => {
                    if (croppedBlob) {
                        const storage = getStorage();
                        const storageRef = ref(storage, `images/${Date.now()}.jpg`);

                        uploadBytes(storageRef, croppedBlob).then(async (snapshot) => {
                            const downloadURL = await getDownloadURL(snapshot.ref);
                            onImageUpload(downloadURL); // Call the callback with the uploaded cropped image URL
                        });
                    }
                }, blob.type);
            };
            image.src = imageSrc; // Set the image source to the original image source
        } finally {
            setLoading(false);
            setImageSrc(null); // Reset the image source
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        if (imageSrc && croppedAreaPixels) {
            setLoading(true);
            try {
                // Save the cropped image before navigating
                const response = await fetch(imageSrc);
                const blob = await response.blob();
                const canvas = document.createElement('canvas');
                const image = new Image();

                image.onload = () => {
                    const { width, height, x, y } = croppedAreaPixels;
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx?.drawImage(image, x, y, width, height, 0, 0, width, height);

                    canvas.toBlob(async (croppedBlob) => {
                        if (croppedBlob) {
                            const storage = getStorage();
                            const storageRef = ref(storage, `images/${Date.now()}.jpg`);

                            uploadBytes(storageRef, croppedBlob).then(async (snapshot) => {
                                const downloadURL = await getDownloadURL(snapshot.ref);
                                onImageUpload(downloadURL); // Save the uploaded image URL
                                onNext(); // Navigate to the next page
                            });
                        }
                    }, blob.type);
                };
                image.src = imageSrc;
            } catch (error) {
                console.error("Error saving the image:", error);
            } finally {
                setLoading(false);
            }
        } else if (uploadedImage) {
            // If there's already an uploaded image, directly navigate to the next page
            onNext();
        } else {
            alert("Please upload an image before proceeding.");
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                width: '100%',
                maxWidth: '393px',
                mx: 'auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            {/* Header */}
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '28px', textAlign: 'center', mb: 2 }}>
                Time to put a face to your name
            </Typography>

            {/* Image Upload or Crop */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '240px',
                    height: '240px',
                    border: '2px dashed #007AFF',
                    borderRadius: '12px',
                    mb: 3,
                    backgroundColor: '#F5F5F5',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : imageSrc ? (
                    <>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={handleCropComplete}
                        />
                        <Box sx={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setImageSrc(null);
                                    onImageUpload(null); // Reset the uploaded image
                                }}
                                sx={{ mr: 1, backgroundColor: '#ccc', color: '#000' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSaveCroppedImage}
                                sx={{ backgroundColor: '#007AFF', color: '#fff' }}
                            >
                                Save
                            </Button>
                        </Box>
                    </>
                ) : uploadedImage ? (
                    <img
                        src={uploadedImage}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <>
                        <AddAPhotoIcon sx={{ fontSize: '48px', color: '#007AFF' }} />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                cursor: 'pointer',
                            }}
                        />
                    </>
                )}
            </Box>

            {/* Navigation */}
            <IconButton
                onClick={handleSubmit}
                sx={{ alignSelf: 'flex-end', marginTop: '24px', backgroundColor: '#007AFF', color: '#fff' }}
            >
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};

export default ImageUploadScreen;