import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app'; // Using compat if you are using FirebaseUI with compat
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { auth } from '../services/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom'; // Use navigate for routing
import './overrides/FirebaseUIOverrides.css'; // Import custom styles
import { getAuth, signInWithPopup, FacebookAuthProvider, UserCredential } from 'firebase/auth'; // Import Firebase authentication and Facebook provider

const PhoneVerification = () => {
    const navigate = useNavigate(); // React Router hook for navigation
    const location = useLocation(); // React Router hook for location
    const { data: event } = location.state || {}; // Destructure data from state 


    const handleConnectWithFacebook = () => {
        const auth = getAuth(); // Initialize Firebase auth
        const provider = new FacebookAuthProvider(); // Initialize Facebook provider


        signInWithPopup(auth, provider)
            .then((result) => {
                // Ensure TypeScript recognizes the additionalUserInfo property
                const userCredential = result as UserCredential & {
                    additionalUserInfo?: {
                        isNewUser?: boolean;
                    };
                };

                // Check if the user is new
                const isNewUser = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
                
                if (isNewUser) {
                    console.log('This is a new user, setting up profile.');
                    navigate('/onboarding', { state: { data: event } });
                } else {
                    console.log('This is an existing user.');
                    navigate('/events');
                }
            })
            .catch((error) => {
                console.error('Error during Facebook sign-in:', error);
            });
    };


    useEffect(() => {
        // Set Firebase auth persistence to local (persistent across browser sessions)
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                // Check if FirebaseUI instance already exists; otherwise, initialize it
                let ui = firebaseui.auth.AuthUI.getInstance();
                if (!ui) {
                    ui = new firebaseui.auth.AuthUI(auth); // Use the initialized auth object here
                }

                // FirebaseUI configuration
                const uiConfig = {
                    signInOptions: [
                        {
                            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                            recaptchaParameters: {
                                type: 'image', // 'audio' or 'image'
                                size: 'normal', // 'invisible' or 'compact' or 'normal'
                                badge: 'bottomleft', // 'bottomright' or 'inline' applies to invisible.
                            },
                            defaultCountry: 'IL', // Set default country
                            whitelistedCountries: ['+972'], // Specify whitelisted countries
                        }
                    ],
                    callbacks: {
                        signInSuccessWithAuthResult: (authResult: any) => {
                            console.log('User successfully signed in:', authResult);
                            const isNewUser = authResult.additionalUserInfo?.isNewUser;
                            // If it's a new user, perform some additional setup
                            if (isNewUser) {
                                console.log('This is a new user, setting up profile.');
                                // For new users, you can create a document in Firestore, etc.
                                navigate('/onboarding', { state: { data: event } });
                            } else {
                                console.log('This is an existing user.');
                                navigate('/events');
                            }

                            // Return false to prevent automatic redirection
                            return false;
                        },
                        signInFailure: (error: any) => {
                            console.error('Sign in failed:', error);
                            // Handle sign-in errors
                        }
                    },
                    credentialHelper: firebaseui.auth.CredentialHelper.NONE, // Disable Google Auth credential helper
                };

                // Start the FirebaseUI widget
                ui.start('#firebaseui-auth-container', uiConfig);

                // Cleanup the FirebaseUI instance on component unmount
                return () => {
                    if (ui) {
                        ui.reset();
                    }
                };
            })
            .catch((error) => {
                console.error('Error setting persistence:', error);
            });
    }, [navigate]);

    return (
        <div>
            <h2>Hey there! We need your digits to stay in touch!</h2>
            <div id="firebaseui-auth-container"></div> {/* FirebaseUI will render here */}
            {/* <button onClick={handleConnectWithFacebook} style={{
                backgroundColor: '#3b5998',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto',
                marginTop: '40px'
            }}>
                <img src="https://www.facebook.com/images/fb_icon_325x325.png" alt="Facebook logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                Connect with Facebook
            </button> */}
        </div>
    );
};

export default PhoneVerification;