import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, TabList, Tab } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Event, Person, Home } from '@mui/icons-material';
import './overrides/TabOverrides.css'; // Import the CSS file

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const TabLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation(); // Get the current route

    const getTabStyle = (path: string) => ({
        ...styles.tabStyle,
        iconStyle: location.pathname === path ? styles.activeIconStyle : styles.iconStyle,
        labelStyle: location.pathname === path ? styles.activeLabelStyle : styles.labelStyle,
    });

    return (
        <div style={styles.containerStyle}>
            {/* Content Area */}
            <div style={styles.contentStyle}>{children}</div>

            {/* Footer Tabs */}
            <div style={styles.footerTabsStyle}>
                <div style={styles.backgroundStyle}>
                    <Tabs>
                        <TabList style={styles.tabListStyle}>
                            {/* Home Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/about" style={styles.linkStyle}>
                                    <Home
                                        className="icon"
                                        style={
                                            location.pathname === '/about'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/about'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        About
                                    </span>
                                </Link>
                            </Tab>

                            {/* Events Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/events" style={styles.linkStyle}>
                                    <Event
                                        className="icon"
                                        style={
                                            location.pathname === '/events'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/events'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        Events
                                    </span>
                                </Link>
                            </Tab>

                            {/* Profile Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/profile" style={styles.linkStyle}>
                                    <Person
                                        className="icon"
                                        style={
                                            location.pathname === '/profile'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/profile'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        Profile
                                    </span>
                                </Link>
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : 'unset',
    },
    contentStyle: {
        flex: 1, // Takes up all remaining space above footer
        overflow: 'auto', // Allow content to scroll if needed
        paddingBottom: '40px', // Space for the footer tabs
        padding: '10px 8px',
        width: isMobile ? '100%' : '375px',
        },
        footerTabsStyle: {
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: isMobile ? '100%' : '375px',
        height: '70px',
        zIndex: 1000,
        },
        backgroundStyle: {
        width: '100%',
        height: '90px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px 30px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: '0px',
        gap: '10px',
        },
        tabListStyle: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '0px',
        },
        tabStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontFamily: 'Arialsans-serif, Arial, sans-serif',
        fontWeight: '500',
        },
        linkStyle: {
        color: '#4867B7',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textDecoration: 'none',
        gap: '5px',
        },
        iconStyle: {
        fontSize: '24px',
        color: '#AAAAAA', // Default color for unselected icons
    },
    activeIconStyle: {
        fontSize: '24px',
        color: '#5D9BFC', // Active color for selected icons
    },
    labelStyle: {
        color: '#AAAAAA', // Default label color
    },
    activeLabelStyle: {
        color: '#4867B7', // Active label color
    },
};

export default TabLayout;