import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import MessageModal from '../../../../shared/components/MessageModal'; // Assuming MessageModal is available

// Define the props for the InfoScreen component
interface InfoScreenProps {
    onNext: () => void; // Callback for when the user clicks the next button
    onBack: () => void; // Callback for when the user clicks the back button
    onUpdate: (field: string, value: string) => void; // Callback for updating the bio and social media URL
    bio: string; // The user's bio
    socialMediaUrl: string; // The user's social media URL
    occupation: string; // The user's social media URL
}

// InfoScreen component for collecting user bio and social media URL
const InfoScreen: React.FC<InfoScreenProps> = ({ onNext, onBack, onUpdate, bio, socialMediaUrl, occupation }) => {
    const [bioError, setBioError] = useState(''); // State for bio validation error message
    const [socialMediaError, setSocialMediaError] = useState(''); // State for social media URL validation error message
    const [occupationError, setOccupationError] = useState(''); // State for social media URL validation error message
    const [afterRegisterModal, setAfterRegisterModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Validate the bio and social media URL fields
    const validate = () => {
        let valid = true;
        const errors: { bio?: string; socialMedia?: string; occupation?: string } = {};

        if (bio.trim().length < 10) {
            errors.bio = 'Bio must be at least 10 characters.';
            valid = false;
        }

        if (socialMediaUrl.trim() === '') {
            errors.socialMedia = 'Social media cannot be empty.';
            valid = false;
        }

        if (occupation.trim().length < 3) {
            errors.occupation = 'Occupation must be at least 3 characters.';
            valid = false;
        }

        setBioError(errors.bio || '');
        setSocialMediaError(errors.socialMedia || '');
        setOccupationError(errors.occupation || '');

        if (!valid) {
            setErrorMessage(
                errors.bio || errors.socialMedia || errors.occupation || 'Unknown error occurred. Please try again.'
            );
            setAfterRegisterModal(true);
        }

        return valid;
    };


    const handleDoneRequest = () => {
        setAfterRegisterModal(false);
    };

    // Handle the next button click
    const handleNext = () => {
        try {
            if (validate()) {
                onNext(); // Call the onNext callback if validation passes
            } else {
                console.error("Validation failed.");
            }
        } catch (error) {
            console.error("An error occurred during validation or next handling:", error);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                width: '100%',
                maxWidth: '393px',
                mx: 'auto',
                boxSizing: 'border-box',
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#333',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '22px', textAlign: 'center', mb: 6 }}>
                Your bio is super important for connecting and approving you for events!
            </Typography>

            <Typography variant="body2" sx={{ alignSelf: 'flex-start', fontSize: '13px', color: '#6D6D6D', textAlign: 'left', margin: '0px', mb: 1 }}>
                FB/Insta user (example: salonim.live)
            </Typography>

            <TextField
                label=""
                variant="outlined"
                placeholder=''
                fullWidth
                value={socialMediaUrl}
                onChange={(e) => onUpdate('socialMediaUrl', e.target.value)} // Update social media URL on change
                error={!!socialMediaError} // Show error if socialMediaError is not empty
                helperText={socialMediaError} // Display social media URL validation error message
                sx={{ mb: 1 }}
            />

            <Typography variant="body2" sx={{ alignSelf: 'flex-start', fontSize: '13px', color: '#6D6D6D', textAlign: 'left', margin: '0px', mb: 1 }}>
                What is your job, and what is your role?
            </Typography>

            <TextField
                label=""
                variant="outlined"
                placeholder=''
                fullWidth
                value={occupation}
                onChange={(e) => onUpdate('occupation', e.target.value)} // Update social media URL on change
                error={!!occupationError} // Show error if socialMediaError is not empty
                helperText={occupationError} // Display social media URL validation error message
                sx={{ mb: 1 }}
            />

            <p style={{ fontSize: '14px', color: '#555', marginTop: '10px', fontWeight: 'normal' }}>
                Your bio will appear on your profile, so put some thought into it 😉.
            </p>
            <TextField
                label="Bio"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                value={bio}
                onChange={(e) => onUpdate('bio', e.target.value)} // Update bio on change
                error={!!bioError} // Show error if bioError is not empty
                helperText={bioError} // Display bio validation error message
                placeholder="Tell us about yourself."
                sx={{ mb: 1, '& .MuiInputBase-input::placeholder': { color: '#6D6D6D', opacity: 1, fontSize: '12px' }, '& .MuiInputBase-input': { color: '#6D6D6D', fontSize: '12px' } }}
            />


            <Typography variant="body2" sx={{ color: '#6D6D6D', mb: 3, fontSize: '14px', textAlign: 'right' }}>
                {bio.length} / 200
            </Typography>




            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button variant="contained" onClick={handleNext}>
                    REGISTER
                </Button>
            </Box>

            {afterRegisterModal && (
                <MessageModal
                    open={afterRegisterModal}
                    onClose={handleDoneRequest}
                    header="Something went wrong 🤔"
                    message={errorMessage}
                    onActionClick={handleDoneRequest}
                />
            )}

        </Box>
    );
};

export default InfoScreen;