import React, { useEffect, useState } from "react";
import { useAuth } from '../context/AuthContext';
 
const PaymentPage = () => {
    const { user } = useAuth(); 
  const [iframeUrl, setIframeUrl] = useState("");

  console.log('user.user.uid22');
  console.log(user);
  const uid = user.user.uid;
  console.log("User ID:", uid);

  useEffect(() => {
    // Define the API call
    const makeApiCall = async () => {
      const apiUrl = "https://secure.cardcom.solutions/api/v11/LowProfile/Create";
      const payload = {
        TerminalNumber: 161524,
        ApiName: "MtaQMsAZEkso7vlxlLQs",
        ReturnValue: "Z12332X",
        UserName: "161524",
        Amount: 1,
        SuccessRedirectUrl: "http://salonim.live/successPayment",
        FailedRedirectUrl: "http://salonim.live/failPayment",
        WebHookUrl: "https://webhook.site/bb71a85e-f864-46ee-be71-ad2db9fb1880",
        Document: {
          To: "test client",
          Email: "test@testDomain.com",
          Products: [
            {
              Description: "אירוע סלונים",
              UnitCost: 1,
            },
          ],
        },
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch payment URL");
        }

        const data = await response.json();

        // Check if the URL is available in the API response
        if (data.Url) {
          setIframeUrl(data.Url);
        } else {
          console.error("No URL returned in the response");
        }
      } catch (error) {
        console.error("Error occurred during the API call:", error);
      }
    };

    // Trigger the API call when the component mounts
    makeApiCall();
  }, []);

  return (
    <div>
      
      {iframeUrl ? (
        <iframe
          src={iframeUrl}
          style={{ width: "100%", height: "900px", border: "none" }}
          title="Payment"
        />
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>
  );
};

export default PaymentPage;
