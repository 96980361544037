import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Alert } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Define the props for the IntroScreen component
interface IntroScreenProps {
    onNext: () => void; // Function to call when moving to the next screen
    setFirstName: (firstName: string) => void; // Function to set the first name
    setBirthday: (birthday: { day: string; month: string; year: string }) => void; // Function to set the birthday
    firstName: string; // The user's first name
    birthday: { day: string; month: string; year: string }; // The user's birthday
}

// IntroScreen component for the Salonim Live app
const IntroScreen: React.FC<IntroScreenProps> = ({ onNext, setFirstName, setBirthday, firstName, birthday }) => {
    const [errors, setErrors] = useState<{ firstName?: string; birthday?: string }>({}); // State to manage validation errors

    // Validate the input fields
    const validate = () => {
        const newErrors: { firstName?: string; birthday?: string } = {};
        
        // Validate first name
        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required.';
        } else if (firstName.length < 2) {
            newErrors.firstName = 'First name must be at least 2 characters long.';
        }

        // Validate birthday
        const day = parseInt(birthday.day, 10);
        const month = parseInt(birthday.month, 10);
        const year = parseInt(birthday.year, 10);

        if (!birthday.day || !birthday.month || !birthday.year) {
            newErrors.birthday = 'Complete birthday is required.';
        } else if (
            isNaN(day) || isNaN(month) || isNaN(year) ||
            day < 1 || day > 31 || month < 1 || month > 12 || year < 1900 || year > new Date().getFullYear()
        ) {
            newErrors.birthday = 'Enter a valid date.';
        }

        setErrors(newErrors); // Update the errors state
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    // Handle form submission
    const handleSubmit = () => {
        if (validate()) {
            onNext(); // Call the onNext function if validation passes
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                maxWidth: '393px',
                margin: '0 auto',
                padding: '16px',
            }}
        >
            <Typography
                variant="h4"
                sx={{ fontWeight: 'bold', fontSize: '38px', marginBottom: '40px' }}
            >
                Oh hey! Let's start with an intro.
            </Typography>

            <Typography sx={{ marginBottom: '8px' }}>Your full name</Typography>
            <TextField
                placeholder="Full Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)} // Update the first name state
                error={!!errors.firstName} // Show error if first name is invalid
                helperText={errors.firstName} // Display error message for first name
                fullWidth
                sx={{ marginBottom: '36px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            />

            <Typography sx={{ marginBottom: '8px' }}>Your birthday</Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '16px',
                    width: '100%',
                }}
            >
                <TextField
                    type="number" // Display number keyboard
                    placeholder="Day"
                    value={birthday.day}
                    onChange={(e) =>
                        setBirthday({ ...birthday, day: e.target.value }) // Update the day in birthday state
                    }
                    error={!!errors.birthday} // Show error if birthday is invalid
                    sx={{ flex: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}
                />
                <TextField
                    type="number" // Display number keyboard
                    placeholder="Month"
                    value={birthday.month}
                    onChange={(e) =>
                        setBirthday({ ...birthday, month: e.target.value }) // Update the month in birthday state
                    }
                    error={!!errors.birthday} // Show error if birthday is invalid
                    sx={{ flex: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}
                />
                <TextField
                    type="number" // Display number keyboard
                    placeholder="Year"
                    value={birthday.year}
                    onChange={(e) =>
                        setBirthday({ ...birthday, year: e.target.value }) // Update the year in birthday state
                    }
                    error={!!errors.birthday} // Show error if birthday is invalid
                    sx={{ flex: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}
                />
            </Box>
            {/* Display error message for birthday */}
            {errors.birthday && <Typography color="error">{errors.birthday}</Typography>} 

            <Typography sx={{ fontSize: '12px', color: '#888' }}>
                It’s never too early to count down
            </Typography>

            <IconButton
                onClick={handleSubmit} // Handle form submission
                sx={{ alignSelf: 'flex-end', marginTop: '24px', backgroundColor: '#007AFF', color: '#fff' }}
            >
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};

export default IntroScreen;
