import React, { useEffect, useState } from 'react';
import { Avatar, AvatarGroup, Box, Typography } from '@mui/material';
import { getRandomLivers } from '../services/userService';

interface PeopleJoinedProps {
    guests: {
        speaker_image: string | undefined; image: string | undefined
    }[];
    random?: {
        speaker_image: string | undefined; image: string | undefined
    }[];
}

const PeopleJoined: React.FC<PeopleJoinedProps> = ({ guests = [] }) => {
    const [randomLivers, setRandomLivers] = useState<any>([]);

    const maxVisibleAvatars = 4; // Number of visible avatars before showing the "+X" design
    // const remainingGuests = guests.length - maxVisibleAvatars;
    const remainingGuests = 10
    //only while testing events cards and members joined
    useEffect(() => {

        const fetchRandomLivers = async () => {
            // Simulate fetching random livers
            const data = await getRandomLivers();
            if (guests.length > 0) {
            const shuffledGuests = [...data, ...guests].sort(() => 0.5 - Math.random());
            setRandomLivers([...shuffledGuests, ...guests]);
            } else {
            setRandomLivers(data);
            }
        };

        fetchRandomLivers();
    }, []);

    return (
        <Box display="flex" alignItems="center">
            {/* Avatars */}
            {guests.length > 0 && (
                <Box display="flex" alignItems="center">
                {randomLivers.slice(0, maxVisibleAvatars).map((person: any, index: React.Key | null | undefined) => (
                    <Avatar
                        key={index}
                        src={person.speaker_image }
                        sx={{
                            width: 30,
                            height: 30,
                            marginLeft: index === 0 ? 0 : '-10px', // Overlap effect
                            border: '2px solid white', // White border for better visibility
                        }}
                    />
                ))}

                {/* +X Avatar */}
                {remainingGuests > 0 && (
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                            marginLeft: '-8px',
                            backgroundColor: '#F0F0F0', // Light grey background
                            color: '#7D7D7D', // Grey text color
                            fontWeight: 'bold',
                            fontSize: '12px',
                            border: '2px solid white',
                        }}
                    >
                        {`+${remainingGuests}`}
                    </Avatar>
                )}
            </Box>
            )}

            {/* Text */}
            <Typography
                sx={{
                    margin: '0px',
                    fontFamily: 'Arialsans-serif',
                    color: '#7D7D7D',
                    marginLeft: '10px',
                    fontSize: '12px',
                }}
            >
                Member Joined
            </Typography>
        </Box>
    );
};

export default PeopleJoined;