import React, { CSSProperties } from 'react';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const PrivacyPolicy: React.FC = () => {
  const containerStyle: CSSProperties = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: isMobile ? '20px' : '70px',
  };

  const titleStyle: CSSProperties = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  };

  const updatedStyle: CSSProperties = {
    fontSize: '1rem',
    color: '#666',
    textAlign: 'center',
    marginBottom: '20px',
  };

  const introStyle: CSSProperties = {
    fontSize: '1.1rem',
    marginBottom: '30px',
    textAlign: 'justify',
  };

  const sectionTitleStyle: CSSProperties = {
    fontSize: isMobile ? '1.8rem' : '1.2rem',
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '15px',
  };

  const subsectionStyle: CSSProperties = {
    marginBottom: '20px',
  };

  const subsectionTitleStyle: CSSProperties = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Privacy Policy</h1>
      <p style={updatedStyle}><strong>Last Updated: 26/11/2024</strong></p>
      <p style={introStyle}>
        Welcome to 'Salonim Live' (the 'App'). This Privacy Policy explains how salonim live ('Company') collects, uses, discloses, and protects your personal information. By using the App, you consent to the practices described in this Privacy Policy.
      </p>

      <h2 style={sectionTitleStyle}>1. Information We Collect</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>1.1 Personal Information:</strong> When you register or use the App, we may collect information such as your name, email address, phone number, and profile photo.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>1.2 Usage Data:</strong> We collect information about how you interact with the App, such as pages visited, actions taken, and device information.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>1.3 Location Data:</strong> With your consent, we may collect your device's location to enhance your experience.</p>

      <h2 style={sectionTitleStyle}>2. How We Use Your Information</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>2.1</strong> To provide and improve the App’s functionality and user experience.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>2.2</strong> To communicate with you regarding updates, promotions, and customer support.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>2.3</strong> To ensure compliance with our Terms of Use and prevent misuse of the App.</p>

      <h2 style={sectionTitleStyle}>3. Information Sharing and Disclosure</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>3.1</strong> We do not sell or rent your personal information to third parties.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>3.2</strong> We may share your information with:</p>
      <ul>
        <li>Service providers who assist us in operating the App.</li>
        <li>Legal authorities if required to comply with applicable laws or respond to legal requests.</li>
      </ul>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>3.3</strong> In the event of a merger or acquisition, your information may be transferred to the new entity.</p>

      <h2 style={sectionTitleStyle}>4. Data Security</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>4.1</strong> We implement reasonable security measures to protect your information from unauthorized access, alteration, or disclosure.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>4.2</strong> While we strive to protect your data, we cannot guarantee complete security due to the nature of the internet.</p>

      <h2 style={sectionTitleStyle}>5. Your Rights</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>5.1</strong> You have the right to access, update, or delete your personal information. Contact us at info@salonim.live for assistance.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>5.2</strong> You can opt out of promotional communications by following the unsubscribe instructions provided in our emails.</p>

      <h2 style={sectionTitleStyle}>6. Cookies and Tracking Technologies</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>6.1</strong> The App may use cookies and similar technologies to enhance user experience and analyze usage patterns.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>6.2</strong> You can manage your cookie preferences through your device or browser settings.</p>

      <h2 style={sectionTitleStyle}>7. Children’s Privacy</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>7.1</strong> The App is not intended for individuals under the age of 13. We do not knowingly collect personal information from children.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>7.2</strong> If you believe we have collected information from a child, please contact us to delete the data.</p>

      <h2 style={sectionTitleStyle}>8. Changes to This Privacy Policy</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>8.1</strong> We may update this Privacy Policy from time to time. Any changes will be posted within the App or sent to registered users.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>8.2</strong> Your continued use of the App after changes are made constitutes your acceptance of the updated policy.</p>

      <h2 style={sectionTitleStyle}>9. Contact Information</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <p>Email: info@salonim.live</p>
    </div>
  );
};

export default PrivacyPolicy;