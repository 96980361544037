// src/components/FallbackProfilePage.tsx

import React from 'react'; // Import React library
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom for navigation

// FallbackProfilePage component for users who are not connected
const FallbackProfilePage: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate function

  // Function to handle sign up button click
  const handleSignUp = () => {
    navigate('/verification'); // Navigate to the verification route
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}> {/* Center align text and add padding */}
      <h1>Profile</h1> {/* Heading for the profile page */}
      <p>You are not connected. Please sign up to access your profile.</p> {/* Message for users who are not connected */}
      <button
        onClick={handleSignUp} // Attach handleSignUp function to button click
        style={{
          padding: '10px 20px', // Button padding
          fontSize: '16px', // Button font size
          backgroundColor: '#007BFF', // Button background color
          color: 'white', // Button text color
          border: 'none', // Remove button border
          borderRadius: '5px', // Button border radius
          cursor: 'pointer', // Change cursor to pointer on hover
        }}
      >
        Sign Up {/* Button text */}
      </button>
    </div>
  );
};

export default FallbackProfilePage; // Export FallbackProfilePage component